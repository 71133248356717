<template>
	<div class="page" style="padding-top: 8px;">
		<!-- <div class="top" @click="toLogin">
			<img class="header" src="@assets/mine_header_holder.png" />
			<div class="login">点击注册或登录</div>
		</div> -->
		<div class="group marginb8 ">
			<div @click="toPatient">
				<item title="患者管理" :showLine="true" type="1" :img="imgs[0]"></item>
			</div>
		</div>
		<div class="group marginb8">
			<div @click="toJoin">
				<item title="我报名的项目" :showLine="true" type="1" :img="imgs[1]"></item>
			</div>
			<div @click="toFav">
				<item title="我收藏的项目" type="1" :img="imgs[2]"></item>
			</div>
		</div>
		<!-- <div class="group">
			<item title="订阅临床试验信息" type="2" :img="imgs[2]"></item>
		</div> -->

		<!-- <div class="desc">开启本功能，当有新的试验信息发布时，您可以收到通知。</div> -->
		<!-- <a :href="'tel:'+ phone">
			<div class="group">

				<item title="联系我们" type="1" :img="imgs[3]"></item>

			</div>
		</a> -->
	</div>
</template>

<script>
	import item from '@/components/mine/item';
	import {toPath} from '@/utils/common';

	export default {
		components: {
			item,
		},
		data() {
			return {
				miniprogram: false,
				phone: '400-033-0800',
				imgs: [
					require('@assets/mine_friends.png'),
					require('@assets/mine_join.png'),
					require('@assets/mine_fav.png'),
					require('@assets/mine_subscribe.png'),
					require('@assets/mine_call.png')
				],

			}
		},
		mounted() {
			
		},
		methods: {
			// toLogin() {
			// 	toPath(this);

			// },
			toPatient() {
				this.$router.push({
					path: '/patientList',
				})
			},
			toJoin() {
				this.$router.push({
					path: '/mineJoin',
				})
				// toPath(this,'/mineJoin');
			},
			toFav() {
				this.$router.push({
					path: '/mineFav',
				})
				// toPath(this,'/mineFav');
			},

		},
	}
</script>

<style lang="less" scoped="scoped">
	.page {
		width: 100%;
		min-height: 100vh;
		background: #F7F7F7;
	}

	.marginb8 {
		margin-bottom: 8px;
	}
	.margint8 {
		margin-top: 8px;
	}
	.top {
		width: 100%;
		height: 124px;
		background: rgba(247, 247, 247, 1);
		display: flex;
		align-items: center;

	}

	.header {
		width: 64px;
		height: 64px;
		border-radius: 100%;
		margin-left: 15px;
	}

	.login {

		height: 17px;
		font-size: 17px;
		font-weight: 500;
		color: rgba(28, 126, 226, 1);
		line-height: 17px;
		margin-left: 20px;
	}

	.desc {
		font-size: 13px;
		font-weight: 400;
		color: rgba(130, 130, 132, 1);
		padding: 15px;
	}
</style>
